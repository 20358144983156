.signup-container {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
}


.signup-left {
  flex: 2;
  position: relative;
  background: url(/public/assets/images/gradient-bg.jpg);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  padding: 20px;
}
.signup-left h3 {
  color: rgb(206, 206, 206);
  font-size: 2rem;
  margin-bottom: 1.25rem !important;
}

.signup-left p {
  color: #fff;
  opacity: 0.8 !important;
  font-size: 12px;
  font-weight: lighter !important;
  margin: 6px 0px;
}

.left-footer {
  position: absolute;
  bottom: 25px;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.left-footer p {
  font-size: 16px;
}

.left-footer div a {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}

.left-footer div {
  display: flex;
  gap: 30px;
}

.signup-right {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}


.signup-form{
  box-shadow: 0 5px 30px 0 rgb(214 215 216 / 57%);
  padding: 20px;
  width: 500px;
}
.signup-form h3{
  font-size: 30px;
  font-weight: 500;
  margin: 20px 0px 30px 0px;
}

.inputs-group{
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.inputs-group input{
  flex: 1;
  border: none;
  outline: none;
  border-bottom: 1px solid rgb(214 215 216);
  padding: 10px;
}
.inputs-group div{
  flex: 1;
}

.signup-form .btn-cont{
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}

.signup-form .btn-cont button:disabled{
  background-color: rgb(93, 93, 138);
  color: #bebebe;
  cursor: not-allowed;
}

@media screen and (max-width: 992px) {
  .signup-container {
    flex-direction: column;
  }
  .signup-left {
    position: static;
    flex: auto;
    padding: 10px 20px;
    background-position: top center;
  }

  .left-footer p,
  .left-footer div a {
    color: #fff;
    color: #000;
    font-size: 14px;
  }
  .signup-right{
      flex: 4;
      height: 60vh !important;
  }
}